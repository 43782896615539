import React from 'react';
import { Link } from 'react-router-dom';

const ChildSafety: React.FC = () => {
  return (
    <div>
    <h1>Commitment to Child Safety</h1>
    <p>At <strong>Bye for Now</strong>, we are dedicated to protecting children from online exploitation, abuse, and harm. 
       We have a <strong>zero-tolerance policy</strong> for child sexual abuse and exploitation (CSAE) and take immediate action 
       against any violations of our safety standards.</p>

    <h2>How We Protect Children</h2>

    <h3>1. Strict Content Moderation</h3>
    <ul>
        <li>Our <strong>human moderation team</strong> manually reviews reported content to identify and remove CSAE material.</li>
        <li>Any accounts found violating our policies are <strong>permanently banned</strong> from the platform.</li>
        <li>We actively monitor for inappropriate content and take swift action when necessary.</li>
    </ul>

    <h3>2. User Reporting & Swift Action</h3>
    <ul>
        <li>Users can <strong>report any content or behavior</strong> that violates our policies directly within the app.</li>
        <li>Every report is <strong>manually reviewed</strong> by our moderation team.</li>
        <li>Verified reports of CSAE material are <strong>escalated to law enforcement</strong> and child protection organizations.</li>
    </ul>

    <h3>3. Law Enforcement & Industry Collaboration</h3>
    <ul>
        <li>We work with <strong>law enforcement agencies</strong> and child protection organizations to report and remove illegal content.</li>
        <li>Our platform complies with global legal standards, including <strong>COPPA</strong> (Children’s Online Privacy Protection Act) 
            and the <strong>EU Digital Services Act (DSA)</strong>.</li>
    </ul>

    <h3>4. Parental Guidance & Safety Resources</h3>
    <ul>
        <li>We encourage parents and guardians to <strong>stay involved</strong> in their child's online activity.</li>
        <li>We provide resources on <strong>online safety best practices</strong> for families and young users.</li>
    </ul>

    <h2>How to Report CSAE Content</h2>
    <div>
        <p>If you encounter any content related to child exploitation, please report it immediately:</p>
        <ul>
            <li><a href="mailto:vocandid@gmail.com">vocandid@gmail.com</a></li>
        </ul>
    </div>

    <h2>Additional Child Safety Resources</h2>
    <ul>
        <li><a href="https://report.cybertip.org" target="_blank">NCMEC CyberTipline</a></li>
        <li><a href="https://inhope.org" target="_blank">INHOPE (Global CSAE Reporting Network)</a></li>
    </ul>

    <p>At <strong>Bye for Now</strong>, we are committed to maintaining a safe and respectful online environment for all users.</p>
    </div>
  );
};

export default ChildSafety;