import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Libraries from './pages/Libraries';
import DeleteAccount from './pages/DeleteAccount';
import Support from './pages/Support';
import ChildSafety from './pages/ChildSafety';
import Invite from './pages/Invite';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/libraries" element={<Libraries />} />
        <Route path="/deleteAccount" element={<DeleteAccount />} />
        <Route path="/support" element={<Support />} />
        <Route path="/childSafety" element={<ChildSafety />}/>
        <Route path="/invite/:path" element={<Invite />} />
      </Routes>
    </Router>
  );
}

export default App;
